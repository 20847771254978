export const getCurrentLang = pathname => {
  if (pathname?.startsWith("/pl/")) {
    return "pl"
  }

  if (pathname?.startsWith("/cs/")) {
    return "cs";
  }

  if (pathname?.startsWith("/sk/")) {
    return "sk";
  }

  return "en"
}
